import WhyCollecting from 'components/WhyCollecting';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { ProgressSpinner } from 'primereact/progressspinner';
import IsNumberVerification from 'components/IsNumberVerification'
import { enumClientTypes, enumDocumentType } from "helpers/enum";
import { getBackendPath } from "helpers/Uri";
import { IAppState } from 'model/IAppState';
import { ICodeName } from 'model/ICommon';
import moment from 'moment';

import * as rdEnum from 'helpers/enum';
import * as orderCustomerDetailsHelper from 'businessHelpers/OrderCustomerDetailsHelper';
import * as docHelper from 'businessHelpers/DocumentHelper';
import * as orderCustomerDetailsModel from 'model/IOrderCustomerDetails';
import * as orderModel from 'model/IOrder';
import * as addressModel from 'model/IAddress';
import * as driverLicenseModel from 'model/IDriverLicense';
import * as docModel from 'model/IDocuments';
import * as orderCustomerDetailsStore from 'stores/OrderCustomerDetails';
import * as orderService from 'services/Order';
import * as docService from 'services/Document';
import * as orderDetailPageVersionStore from 'stores/OrderDetailPageDataVersion';
import { useCountryState } from 'helpers/useCountryState';
import { Page } from '../../../../Page';

const ProvideDetails = (props: any) => {
    const dispatch = useDispatch();
    const countryState = useCountryState();
    const { buttonText } = props;

    const inputFile = useRef<FileUpload>(null);
    const uploadFile1 = useRef<HTMLInputElement>(null);
    const uploadFile2 = useRef<HTMLInputElement>(null);

    const detailsRef = useRef<any>(null);
    const businessRef = useRef<any>(null);
    const billingRef = useRef<any>(null);
    const registrationRef = useRef<any>(null);
    const deliveryRef = useRef<any>(null);

    const orderCustomerDetailsFromStore = useSelector((state: IAppState) => {
        return state.orderCustomerDetails;
    });
    const orderDocumentsFromStore = useSelector((state: IAppState) => {
        return state.orderDocuments;
    });

    const [dialogVisible, setDialogVisible] = useState(false);
    const [pageState, setPageState] = useState<orderCustomerDetailsModel.IOrderCustomerDetails>(orderCustomerDetailsHelper.getInitDetails());
    const [invaidPageState, setInvaidPageState] = useState<orderCustomerDetailsModel.IInvalidOrderCustomerDetails>(orderCustomerDetailsHelper.getInitInvalidCustomerDetails());

    const [driveLicenseImages, setDriveLicenseImages] = useState<docModel.IDocumentItem[]>(docHelper.getInitDriveLicenseImages());
    const [states, setStates] = useState<ICodeName[]>([]);

    const [savingStatus, setSavingStatus] = useState(rdEnum.enumProcessingStatus.Init);

    const [submitMessage, setSubmitMessage] = useState("");
    const [submitStatus, setSubmitStatus] = useState<boolean | null>(null);

    //const [upload1Base64, setUpload1Base64] = useState<any>(null);
    //const [upload2Base64, setUpload2Base64] = useState<any>(null);

    //const [invalidDriveLicense1Image, setInvalidDriveLicense1Image] = useState<boolean>(false);
    //const [invalidDriveLicense2Image, setInvalidDriveLicense2Image] = useState<boolean>(false);

    const [invalidABNLength, setInvalidABNLength] = useState(false)

    const uploadFileMaxSize = 50;

    useEffect(() => {
        if (!isNil(orderCustomerDetailsFromStore)) {
            setPageState(orderCustomerDetailsFromStore);
        }
    }, [orderCustomerDetailsFromStore]);

    useEffect(() => {
        if (!isNil(orderDocumentsFromStore) && dialogVisible) {
            doGetDoc();
        }
    }, [orderDocumentsFromStore, dialogVisible]);

    //const fetchBase64FromUrl = async (inputUrl: string) => {
    //    try {
    //        const response = await fetch(inputUrl);
    //        const blob = await response.blob();

    //        const reader = new FileReader();
    //        reader.onloadend = () => {
    //            if (reader.result) {
    //                console.log(reader.result);
    //                const base64String = reader.result.toString().replace('data:', '').replace(/^.+,/, '');
    //                //console.log(base64String);
    //            }
    //        };
    //        reader.readAsDataURL(blob);
    //    } catch (err) {
    //        //setError('Failed to fetch or convert the URL to Base64');
    //        console.error('Error fetching the image:', err);
    //    }
    //};

    const doGetDoc = async () => {
        let findFrontDocs = orderDocumentsFromStore.filter((m: docModel.IDocumentItem) => m.DocumentType == enumDocumentType.DriveLicenseFront);
        let findFrontDoc: docModel.IDocumentItem = docHelper.getInitDriveLicenseImage();
        if (!isNil(findFrontDocs) && findFrontDocs.length > 0) {
            findFrontDoc = findFrontDocs[findFrontDocs.length - 1];
        }

        let findBackDocs = orderDocumentsFromStore.filter((m: docModel.IDocumentItem) => m.DocumentType == enumDocumentType.DriveLicenseBack);
        let findBackDoc: docModel.IDocumentItem = docHelper.getInitDriveLicenseImage();
        if (!isNil(findBackDocs) && findBackDocs.length > 0) {
            findBackDoc = findBackDocs[findBackDocs.length - 1];
        }

        setDriveLicenseImages([
            findFrontDoc,
            findBackDoc
        ]);

        //if (!isNil(findDocs) && findDocs.length > 0) {
        //    let requestFrontData: docModel.IDocument = {
        //        YanaCustomerID: orderCustomerDetailsFromStore?.yanaCustomerID || "",
        //        YanaCustomerNo: orderCustomerDetailsFromStore?.yanaCustomerNo || "",
        //        YanaOrderID: orderCustomerDetailsFromStore?.yanaOrderID || "",
        //        YanaOrderNo: orderCustomerDetailsFromStore?.yanaOrderNo || "",
        //        Country: orderCustomerDetailsFromStore?.country || "",
        //        Documents: [findDocs[findDocs.length - 1]]
        //    };
        //    let response = await docService.getSpecificDocuments(requestFrontData);
        //    if (response.status) {
        //        fileName = response.data[0].reportName;
        //        let downloadUrl = response.data[0].downloadUrl;

        //    }
        //}
    }

    const doGetStates = async () => {
        let states = await countryState.getStates(pageState?.country || "");
        setStates(states);
    }

    const selectedLocationFromStore = useSelector((state: IAppState) => {
        return state.selectedDelivery;
    });


    useEffect(() => {
        doGetStates();
    }, [pageState?.country]);

    const onBillingStateChange = (e: any) => {
        setPageState({
            ...pageState,
            billingAddress: {
                ...pageState.billingAddress,
                State: e.value,
            }
        });
    }

    const onRegoStateChange = (e: any) => {
        setPageState({
            ...pageState,
            regoAddress: {
                ...pageState.regoAddress,
                State: e.value,
            }
        });
    }

    const onDeliveryStateChange = (e: any) => {
        setPageState({
            ...pageState,
            deliveryAddress: {
                ...pageState.deliveryAddress,
                State: e.value,
            }
        });
    }

    const onShowDialog = () => {
        setDialogVisible(true);
    }

    const onHideDialog = () => {
        setDialogVisible(false);
    }

    const isNumberKey = (e: any) => {
        IsNumberVerification(e);
    }

    const validationInput = (): boolean => {
        let invalidForm = false;

        let invalidBirthday = false;
        let invalidDriveLicenseNo = false;
        let invalidDrivLicenseExpDate = false;

        let isInvalid = false;
        let invalidABN = false;
        let invalidABNLength = false;
        let invalidBusiness = false;
        let invalidFirstname = false;
        let invalidLastname = false;
        let invalidEmail = false;
        let invalidPhone1 = false;

        if (isNil(pageState?.birthday)) {
            invalidBirthday = true;
            invalidForm = true;
        }
        else {
            let validBirthday = moment(pageState?.birthday, "DD/MM/YYYY");
            invalidBirthday = !validBirthday.isValid();
            if (invalidBirthday) invalidForm = true;
        }

        if (isNil(pageState?.driverLicense?.driverLicenseNo) || pageState?.driverLicense?.driverLicenseNo == "") {
            invalidDriveLicenseNo = true;
            invalidForm = true;
        }

        if (isNil(pageState?.driverLicense?.driverLicenseExpiryDate)) {
            invalidDrivLicenseExpDate = true;
            invalidForm = true;
        }
        else {
            let validExpdate = moment(pageState?.driverLicense?.driverLicenseExpiryDate, "DD/MM/YYYY");
            invalidDrivLicenseExpDate = !validExpdate.isValid();
            if (invalidDrivLicenseExpDate) invalidForm = true;
        }

        if (pageState.customerType == enumClientTypes.Business.Code) {
            if (isNil(pageState?.abn) || pageState?.abn == "") {
                invalidABN = true;
                invalidForm = true;
            } else {
                if (pageState.country == rdEnum.enumCountryCode.Australia) {
                    if (pageState.abn.length != 11) {
                        invalidABNLength = true;
                        isInvalid = true;
                        invalidForm = true;
                    }
                }
                else if (pageState.country == rdEnum.enumCountryCode.NewZealand) {
                    if (pageState.abn.length != 13) {
                        invalidABNLength = true;
                        isInvalid = true;
                        invalidForm = true;
                    }
                }
            }

            if (isNil(pageState?.businessName) || pageState?.businessName == "") {
                invalidBusiness = true;
                invalidForm = true;
            }
        }

        if (isNil(pageState?.firstname) || pageState?.firstname == "") {
            invalidFirstname = true;
            invalidForm = true;
        }

        if (isNil(pageState?.lastname) || pageState?.lastname == "") {
            invalidLastname = true;
            invalidForm = true;
        }

        if (isNil(pageState?.email) || pageState?.email == "") {
            invalidEmail = true;
            invalidForm = true;
        }
        else {
            invalidEmail = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pageState?.email);
            if (invalidEmail) invalidForm = true;
        }

        if (isNil(pageState?.phone) || pageState?.phone == "") {
            invalidPhone1 = true;
            invalidForm = true;
        }

        let invalidDriveLicenseImage1 = false;
        if (isNil(driveLicenseImages[0].DocumentName) || driveLicenseImages[0].DocumentName == "") {
            invalidDriveLicenseImage1 = true;
            invalidForm = true;
        }

        let invalidDriveLicenseImage2 = false;
        if (isNil(driveLicenseImages[1].DocumentName) || driveLicenseImages[1].DocumentName == "") {
            invalidDriveLicenseImage2 = true;
            invalidForm = true;
        }

        console.log(driveLicenseImages[0].InvalidDocment, driveLicenseImages[1].InvalidDocment);

        const validateDetails = () => {
            if (invalidBirthday || invalidDriveLicenseNo || invalidDrivLicenseExpDate || invalidDriveLicenseImage1 || invalidDriveLicenseImage2 ) {
                return true; 
            }            
        };

        const validateBusiness = () => {
            if (invalidABN || invalidBusiness || invalidFirstname || invalidLastname || invalidEmail || invalidPhone1 || invalidABNLength) {
                return true; 
            }     
        };
    
        // const validateBilling = () => {
         
        //     return true; 
        // };
    
        // const validateRegistration = () => {
           
        //     return true; 
        // };
    
        // const validateDelivery = () => {
           
        //     return true; 
        // };

        console.log(validateDetails(), validateBusiness())

        let errorRefs = [];
        
        // Validate each section and collect refs for sections with errors
        if (validateDetails()) errorRefs.push(detailsRef);
        if (validateBusiness()) errorRefs.push(businessRef);
        // if (!validateBilling()) errorRefs.push(billingRef);
        // if (!validateRegistration()) errorRefs.push(registrationRef);
        // if (!validateDelivery()) errorRefs.push(deliveryRef);
        console.log(errorRefs)
        // If there are errors, scroll to the first section with an error
        if (errorRefs.length > 0) {
            errorRefs[0].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            // If no errors, you can proceed with form submission or further actions
            console.log('Form is valid and ready to submit!');
        }

        setInvaidPageState({
            ...invaidPageState,
            invalidBirthday: invalidBirthday,
            invalidDriverLicense: {
                ...invaidPageState.invalidDriverLicense,
                invalidExpiryDate: invalidDrivLicenseExpDate,
                invalidNo: invalidDriveLicenseNo
            },
            invalidFirstname: invalidFirstname,
            invalidLastname: invalidLastname,
            invalidEmail: invalidEmail,
            invalidPhone: invalidPhone1,
            invalidABN: invalidABN,
            invalidBusinessname: invalidBusiness
        });

        setInvalidABNLength(invalidABNLength);

        let updateDoc1 = {
            ...driveLicenseImages[0],
            InvalidDocment: invalidDriveLicenseImage1
        };
        let updateDoc2 = {
            ...driveLicenseImages[1],
            InvalidDocment: invalidDriveLicenseImage2
        };
        setDriveLicenseImages([
            updateDoc1,
            updateDoc2
        ]);

        if (invalidForm) {
            let dialogContentRef = document.getElementsByClassName("p-dialog-content");   //order-details-dialog 
            if (dialogContentRef && dialogContentRef.length > 0) {
                dialogContentRef[0].scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }

        return invalidForm;
    }

    const onSaveAndSubmit = async (isSubmit: boolean) => {
        let invalid = validationInput();
        if (invalid) return;
        let updateData = { ...pageState, actionType: isSubmit ? "Submit" : "Save" };
        setSavingStatus(rdEnum.enumProcessingStatus.Processing);
        let response = await orderService.updateOrderDetails(updateData, driveLicenseImages);
        setSavingStatus(rdEnum.enumProcessingStatus.Processed);
        if (response.status) {
            dispatch(orderDetailPageVersionStore.setOrderDetailPageDataVersionAction());
            setSubmitStatus(true);
            setSubmitMessage("You have " + (isSubmit ? "submitted" : "saved") + " successfully.");
        }
        else {
            setSubmitStatus(false);
            setSubmitMessage(response.message);
        }
    }

    const onBirthdayChange = (e: any) => {
        setPageState({
            ...pageState,
            birthday: e.value
        });

        setInvaidPageState({
            ...invaidPageState,
            invalidBirthday: isNil(e.value)
        });
    }

    const onDriveLicenseExpDateChange = (e: any) => {
        setPageState({
            ...pageState,
            driverLicense: {
                ...pageState.driverLicense,
                driverLicenseExpiryDate: e.target.value
            }
        });

        setInvaidPageState({
            ...invaidPageState,
            invalidDriverLicense: {
                ...invaidPageState.invalidDriverLicense,
                invalidExpiryDate: isNil(e.target.value) || e.target.value == ""
            }
        });
    }

    const onSelect = async (event: any, isFront: boolean) => {
        // convert file to base64 encoded 
        const file = event.target.files[0];

        let invalidFizeSize = false;
        let invalidFileName = false;


        let fileSize = file.size;
        if (fileSize > uploadFileMaxSize * 1024 * 1024) {
            invalidFizeSize = true;
        }

        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
        if (file && allowedExtensions.test(file.name)) {
        }
        else {
            invalidFileName = true;
        }


        if (invalidFizeSize || invalidFileName) {
            let invalidMessage = "";
            if (invalidFileName) {
                invalidMessage = "Upload file is invalid.";
            }
            else if (invalidFizeSize) {
                invalidMessage = "Upload size is invalid";
            }
           

            if (isFront) {
                let updateDoc = {
                    ...driveLicenseImages[0],
                    InvalidDocment: true,
                    InvalidReason: invalidMessage,
                };
                setDriveLicenseImages([
                    updateDoc,
                    driveLicenseImages[1]
                ]);
            }
            else {
                let updateDoc = {
                    ...driveLicenseImages[1],
                    InvalidDocment: true,
                    InvalidReason: invalidMessage,
                };
                setDriveLicenseImages([
                    driveLicenseImages[0],
                    updateDoc,
                ]);
            }
            return;
        }


        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const base64data = (reader.result)?.toString().split(',')[1];
            //console.log(base64data);
            if (isFront) {
                let updateDoc = {
                    ...driveLicenseImages[0],
                    DocumentName: file.name,
                    DocumentType: rdEnum.enumDocumentType.DriveLicenseFront,
                    Base64: base64data
                };
                setDriveLicenseImages([
                    updateDoc,
                    driveLicenseImages[1]
                ]);
            }
            else {
                let updateDoc = {
                    ...driveLicenseImages[1],
                    DocumentName: file.name,
                    DocumentType: rdEnum.enumDocumentType.DriveLicenseBack,
                    Base64: base64data
                };
                setDriveLicenseImages([
                    driveLicenseImages[0],
                    updateDoc
                ]);
            }
        }
    }


    const showInvalidFileError = (isFront: boolean) => {
        if (isFront) {
            if (driveLicenseImages[0].InvalidDocment) {
                if (isNil(driveLicenseImages[0].InvalidReason) || driveLicenseImages[0].InvalidReason == "") {
                    return (<small id="drivelicense-front-image-help" className="p-error block">Please Upload Driver Licence Front Image</small>);
                }
                else {
                    return (<small id="drivelicense-front-image-help" className="p-error block">{driveLicenseImages[0].InvalidReason}</small>);
                }
            }
        }
        else {
            if (driveLicenseImages[1].InvalidDocment) {
                if (isNil(driveLicenseImages[1].InvalidReason) || driveLicenseImages[1].InvalidReason == "") {
                    return (<small id="drivelicense-front-image-help" className="p-error block">Please Upload Driver Licence Front Image</small>);
                }
                else {
                    return (<small id="drivelicense-front-image-help" className="p-error block">{driveLicenseImages[1].InvalidReason}</small>);
                }
            }
        }
        return (<></>);

    }

    return (
        <>
            <div className='variant-btn-panel'>
                <Button className='variant-btn'
                    onClick={() => onShowDialog()}
                >
                    <h6>{buttonText}</h6>
                </Button>
            </div>
            <Dialog className='order-details-dialog' visible={dialogVisible}
                position='bottom' style={{ maxWidth: '1100px' }}
                draggable={false} resizable={true} onHide={() => onHideDialog()}>
                {/* <div className='order-details-dialog-container'> */}
                    <div className='provide-info-container' ref={detailsRef}>
                        <h3>PROVIDE YOUR DETAILS</h3>
                        <Row>

                            <Col lg={6} className='provide-info-item' style={{ paddingBottom: '36px' }}>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            {/*<InputText id="birthday"*/}
                                            {/*    aria-describedby="birthday-help"*/}
                                            {/*    placeholder="Date of birth: dd/mm/yyyy"*/}
                                            {/*    value={pageState?.birthday || ""}*/}
                                            {/*    maxLength={10}*/}
                                            {/*    onChange={(e: any) => {*/}
                                            {/*        setPageState({*/}
                                            {/*            ...pageState,*/}
                                            {/*            birthday: e.target.value*/}
                                            {/*        });*/}
                                            {/*        setInvaidPageState({*/}
                                            {/*            ...invaidPageState,*/}
                                            {/*            invalidBirthday: isNil(e.target.value) || e.target.value == ""*/}
                                            {/*        });*/}
                                            {/*    }}*/}
                                            {/*/>*/}

                                            <InputMask id="birthday"
                                                aria-describedby="birthday-help"
                                                placeholder="Date of birth: dd/mm/yyyy"
                                                value={pageState?.birthday || ""}
                                                mask="99/99/9999"
                                                onChange={(e: any) => {
                                                    setPageState({
                                                        ...pageState,
                                                        birthday: e.target.value
                                                    });
                                                    setInvaidPageState({
                                                        ...invaidPageState,
                                                        invalidBirthday: isNil(e.target.value) || e.target.value == ""
                                                    });
                                                }}
                                            />
                                            <label>Date of birth: dd/mm/yyyy</label>

                                        </span>
                                    </div>
                                    {invaidPageState?.invalidBirthday && (
                                        <small id="birthday-help" className="p-error block">Require a Valid Date of Birth.</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item' style={{ paddingBottom: '36px' }}>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            {/*nz driver license: xx123456. Version 999*/}
                                            <InputText id="driverlicense_no"
                                                aria-describedby="driverlicense_no-help"
                                                placeholder="Driver Licence Number"
                                                value={pageState?.driverLicense?.driverLicenseNo || ""}
                                                maxLength={pageState?.country == rdEnum.enumCountryCode.Australia ? 9 : 22}
                                                keyfilter={pageState?.country == rdEnum.enumCountryCode.Australia ? "int" : "alphanum"}
                                                onChange={(e: any) => {
                                                    setPageState({
                                                        ...pageState,
                                                        driverLicense: {
                                                            ...pageState.driverLicense,
                                                            driverLicenseNo: e.target.value
                                                        }
                                                    });
                                                    setInvaidPageState({
                                                        ...invaidPageState,
                                                        invalidDriverLicense: {
                                                            ...invaidPageState.invalidDriverLicense,
                                                            invalidNo: isNil(e.target.value) || e.target.value == ""
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>Driver Licence Number</label>
                                        </span>
                                    </div>
                                    {invaidPageState?.invalidDriverLicense?.invalidNo && (
                                        <small id="driverlicense_no-help" className="p-error block">Require Valid Driver Licence No</small>
                                    )}
                                </div>
                            </Col>

                            <Col lg={6} className='provide-info-item' style={{ paddingBottom: '36px' }}>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            {/*<InputText id="driveLicenseExpDate"*/}
                                            {/*    aria-describedby="driveLicenseExpDate-help"*/}
                                            {/*    placeholder="Driver's Licence Expiry Date: dd/mm/yyyy"*/}
                                            {/*    maxLength={10}*/}
                                            {/*    value={pageState?.driverLicense?.driverLicenseExpiryDate || ""}*/}
                                            {/*    onChange={(e: any) => {*/}
                                            {/*        onDriveLicenseExpDateChange(e);*/}
                                            {/*    }}*/}
                                            {/*/>*/}

                                            <InputMask id="driveLicenseExpDate"
                                                aria-describedby="driveLicenseExpDate-help"
                                                placeholder="Driver's Licence Expiry Date: dd/mm/yyyy"
                                                mask="99/99/9999"
                                                value={pageState?.driverLicense?.driverLicenseExpiryDate || ""}
                                                onChange={(e: any) => {
                                                    onDriveLicenseExpDateChange(e);
                                                }}
                                            />

                                            <label>Driver's Licence Expiry Date: dd/mm/yyyy</label>
                                        </span>
                                    </div>
                                    {invaidPageState?.invalidDriverLicense?.invalidExpiryDate && (
                                        <small id="driveLicenseExpDate-help" className="p-error block">Require a valid expiry date of Driver Licence.</small>
                                    )}
                                    {/* <WhyCollecting />*/}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item' onClick={() => {
                                        if (uploadFile1.current != null) {
                                            uploadFile1?.current?.click();
                                        }
                                    }}>
                                        <span className="wrapper-box">
                                            {!isNil(driveLicenseImages) && driveLicenseImages.length > 0 && (
                                                <InputText id="driveLicenseFrontImage"
                                                    className="uplad-file-input"
                                                    aria-describedby="driveLicenseFrontImage-help"
                                                    placeholder="Front Driver's Licence Upload"
                                                    value={driveLicenseImages[0].DocumentName}
                                                />
                                            )}
                                            <label>Front Driver's Licence Upload</label>
                                        </span>
                                        <input type="file" className="upload-file-wrapper"
                                            accept=".jpg, .jpeg, .png, .gif, .pdf"
                                            ref={uploadFile1}
                                            onChange={(e: any) => {
                                                onSelect(e, true);
                                            }}
                                        />
                                        {showInvalidFileError(true)}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item' onClick={() => {
                                        if (uploadFile2.current != null) {
                                            uploadFile2?.current?.click();
                                        }
                                    }}>
                                        <span className="wrapper-box">
                                            {!isNil(driveLicenseImages) && driveLicenseImages.length > 1 && (
                                                <InputText id="driveLicenseBackImage"
                                                    className="uplad-file-input"
                                                    aria-describedby="driveLicenseBackImage-help"
                                                    placeholder="Rear Driver's Licence Upload"
                                                    value={driveLicenseImages[1].DocumentName}
                                                />
                                            )}
                                            <label>Rear Driver's Licence Upload</label>
                                        </span>
                                        <input type="file" className="upload-file-wrapper"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            ref={uploadFile2}
                                            onChange={(e: any) => {
                                                onSelect(e, false);
                                            }}
                                        />
                                        {showInvalidFileError(false)}
                                        {/*{driveLicenseImages[1].InvalidDocment && (*/}
                                        {/*    <small id="drivelicense-front-image-help" className="p-error block">Please Upload Driver License Back Image</small>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='personal-info-container' ref={businessRef}>
                        <h3>{pageState.customerType == enumClientTypes.Business.Code ? "BUSINESS" : "PERSONAL"} INFORMATION</h3>
                        <Row>
                            {pageState.customerType == enumClientTypes.Business.Code && (
                                <React.Fragment>
                                    <Col lg={6} className='provide-info-item'>
                                        <div className='provide-info-inner'>
                                            <div className='input-item'>
                                                <span className="wrapper-box">
                                                    <InputText id="business-name" aria-describedby="business-name-help"
                                                        placeholder="Business Name"
                                                        value={pageState.businessName}
                                                        onChange={(e) => {
                                                            setPageState({
                                                                ...pageState,
                                                                businessName: e.target.value,
                                                            });

                                                            setInvaidPageState({
                                                                ...invaidPageState,
                                                                invalidBusinessname: isNil(e.target.value) || e.target.value == ""
                                                            });
                                                        }}
                                                    />
                                                    <label>Business Name</label>
                                                </span>
                                            </div>
                                    </div>
                                    {invaidPageState?.invalidBusinessname && (
                                        <small id="business-name-help" className="p-error block">Require Valid Business name</small>
                                    )}
                                    </Col>
                                    <Col lg={6} className='provide-info-item'>
                                        <div className='provide-info-inner'>
                                            <div className='input-item'>
                                                <span className="wrapper-box">
                                                    <InputText id="abn" aria-describedby="abn-help"
                                                        placeholder="ABN"
                                                        value={pageState.abn}
                                                        onChange={(e) => {
                                                            setPageState({
                                                                ...pageState,
                                                                abn: e.target.value
                                                            });

                                                            setInvaidPageState({
                                                                ...invaidPageState,
                                                                invalidABN: isNil(e.target.value) || e.target.value == ""
                                                            });

                                                            setInvalidABNLength(false)
                                                        }}
                                                    />
                                                    {
                                                        orderCustomerDetailsFromStore?.country == rdEnum.enumCountryCode.Australia
                                                            ?
                                                            <label>ABN</label>
                                                            :
                                                            <label>NZBN</label>
                                                    }
                                                </span>
                                            </div>
                                            {invaidPageState?.invalidABN && (
                                                <small id="abn-help" className="p-error block">
                                                    {/* Require Valid {orderCustomerDetailsFromStore?.country == rdEnum.enumCountryCode.Australia ? "ABN" :"NZBN"} */}
                                                    Require valid
                                                    {
                                                        orderCustomerDetailsFromStore?.country == rdEnum.enumCountryCode.Australia
                                                            ?
                                                            <span>ABN</span>
                                                            :
                                                            <span>NZBN</span>
                                                    } code
                                                </small>
                                            )}
                                            {invalidABNLength && (
                                                <small id="abn-help" className="p-error block">
                                                    {/* Require Valid {orderCustomerDetailsFromStore?.country == rdEnum.enumCountryCode.Australia ? "ABN" :"NZBN"} */}
                                                    {
                                                        orderCustomerDetailsFromStore?.country == rdEnum.enumCountryCode.Australia
                                                            ?
                                                            <span>ABN</span>
                                                            :
                                                            <span>NZBN</span>
                                                    }
                                                    &nbsp;length should be {orderCustomerDetailsFromStore?.country == rdEnum.enumCountryCode.Australia ? 11 : 13} digits.
                                                </small>
                                            )}
                                        </div>
                                    </Col>
                                </React.Fragment>
                            )}
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="firstname" aria-describedby="firstname-help"
                                                className={classNames({ "p-invalid": invaidPageState?.invalidFirstname })}
                                                placeholder=" "
                                                style={{ borderBottom: invaidPageState?.invalidFirstname ? '3px solid red' : '2px solid #d2d2d2' }}
                                                value={pageState.firstname}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        firstname: e.target.value,
                                                    });

                                                    setInvaidPageState({
                                                        ...invaidPageState,
                                                        invalidFirstname: isNil(e.target.value) || e.target.value == ""
                                                    });
                                                }}
                                            />
                                            <label>First Name *</label>
                                        </span>
                                    </div>
                                    {invaidPageState?.invalidFirstname && (
                                        <small id="fristname-help" className="p-error block">Require Valid First Name</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="middlename" aria-describedby="middlename-help"
                                                placeholder=" "
                                                value={pageState.middlename}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        middlename: e.target.value,
                                                    })
                                                }}
                                            />
                                            <label>Middle Name(Optional)</label>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    {/* <p className='collect-title'>Last Name</p> */}
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="lastname" aria-describedby="lastname-help"
                                                className={classNames({ "p-invalid": invaidPageState?.invalidLastname })}
                                                placeholder=" "
                                                value={pageState.lastname}
                                                style={{ borderBottom: invaidPageState?.invalidLastname ? '3px solid red' : '2px solid #d2d2d2' }}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        lastname: e.target.value,
                                                    });

                                                    setInvaidPageState({
                                                        ...invaidPageState,
                                                        invalidLastname: isNil(e.target.value) || e.target.value == ""
                                                    });
                                                }}
                                            />
                                            <label>Last Name *</label>
                                        </span>
                                    </div>
                                    {invaidPageState?.invalidLastname && (
                                        <small id="lastname-help" className="p-error block">Require Valid Last Name</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="email" aria-describedby="email-help"
                                                className={classNames({ "p-invalid": invaidPageState?.invalidEmail })}
                                                placeholder=" "
                                                value={pageState.email}
                                                style={{ borderBottom: invaidPageState?.invalidEmail ? '3px solid red' : '2px solid #d2d2d2' }}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        email: e.target.value,

                                                    })

                                                    setInvaidPageState({
                                                        ...invaidPageState,
                                                        invalidEmail: isNil(e.target.value) || e.target.value == ""
                                                    });
                                                }}
                                            />
                                            <label>Email Address *</label>
                                        </span>
                                    </div>
                                    {invaidPageState?.invalidEmail && (
                                        <small id="email-help" className="p-error block">Require Valid Email</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="phone" aria-describedby="phone-help"
                                                className={classNames({ "p-invalid": invaidPageState?.invalidPhone })}
                                                placeholder=" "
                                                value={pageState?.phone || ''}
                                                style={{ borderBottom: invaidPageState?.invalidPhone ? '3px solid red' : '2px solid #d2d2d2' }}
                                                onKeyPress={(e: any) => isNumberKey(e)}
                                                onInput={(event: any) => {
                                                    let phone = event.target.value.slice(0, 11);
                                                    setPageState({
                                                        ...pageState,
                                                        phone: phone,
                                                    });

                                                    setInvaidPageState({
                                                        ...invaidPageState,
                                                        invalidPhone: isNil(event.target.value) || event.target.value == ""
                                                    });
                                                }}
                                            />
                                            <label>Phone Number *</label>
                                        </span>
                                    </div>
                                    {invaidPageState?.invalidPhone && (
                                        <small id="lastname-help" className="p-error block">Require Valid Phone Number</small>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="secondaryPhone" aria-describedby="secondaryPhone-help"
                                                className={classNames({ "p-invalid": invaidPageState?.invalidSecondaryPhone })}
                                                placeholder=" "
                                                value={pageState?.secondaryPhone || ''}
                                                style={{ borderBottom: invaidPageState?.invalidSecondaryPhone ? '3px solid red' : '2px solid #d2d2d2' }}
                                                onKeyPress={(e: any) => isNumberKey(e)}
                                                onInput={(event: any) => {
                                                    let secondaryPhone = event.target.value.slice(0, 11);
                                                    setPageState({
                                                        ...pageState,
                                                        secondaryPhone: secondaryPhone,
                                                    });
                                                }}
                                            />
                                            <label>Secondary Phone Number</label>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='provide-address-container' ref={billingRef}>
                        <h3>BILLING ADDRESS</h3>
                        <Row>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="billing-suburb" aria-describedby="billing-suburb-help"
                                                placeholder="Suburb"
                                                value={pageState.billingAddress?.Address1}
                                                disabled={true}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        billingAddress: {
                                                            ...pageState.billingAddress,
                                                            Address1: e.target.value,
                                                        }
                                                    })
                                                }}
                                            />
                                            <label>Address</label>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="billing-suburb" aria-describedby="billing-suburb-help"
                                                placeholder="Suburb"
                                                value={pageState.billingAddress?.Suburb}
                                                disabled={true}
                                                onChange={(e) => {
                                                    setPageState({
                                                        ...pageState,
                                                        billingAddress: {
                                                            ...pageState.billingAddress,
                                                            Suburb: e.target.value,
                                                        }
                                                    })
                                                }}
                                            />
                                            <label>Suburb</label>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="billing-suburb" aria-describedby="billing-suburb-help"
                                                placeholder="Suburb"
                                                value={pageState.billingAddress?.State?.code}
                                                disabled={true}

                                            />
                                            <label>State</label>
                                            {/*onChange={(e) => {*/}
                                            {/*    setPageState({*/}
                                            {/*        ...pageState,*/}
                                            {/*        billingAddress: {*/}
                                            {/*            ...pageState.billingAddress,*/}
                                            {/*            State: e.target.value,*/}
                                            {/*        }*/}
                                            {/*    })*/}
                                            {/*}}*/}
                                            {/*<Dropdown*/}
                                            {/*    value={pageState.billingAddress?.State}*/}
                                            {/*    options={states}*/}
                                            {/*    disabled={true}*/}
                                            {/*    onChange={(e) => onBillingStateChange(e)}*/}
                                            {/*    className='global-location-dropdown'*/}
                                            {/*    optionLabel="name"*/}
                                            {/*    placeholder="State*"*/}
                                            {/*/>*/}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='provide-info-item'>
                                <div className='provide-info-inner'>
                                    <div className='input-item'>
                                        <span className="wrapper-box">
                                            <InputText id="billing-postcode" aria-describedby="billing-postcode-help"
                                                placeholder="Post Code"
                                                value={pageState.billingAddress?.Postcode || ''}
                                                style={{ borderBottom: invaidPageState.invalidBillingAddress.InvalidPostcode ? '3px solid red' : '2px solid #d2d2d2' }}
                                                onKeyPress={(e: any) => isNumberKey(e)}
                                                disabled={true}
                                                onInput={(event: any) => {
                                                    let postcode = event.target.value.slice(0, 4);
                                                    setPageState({
                                                        ...pageState,
                                                        billingAddress: {
                                                            ...pageState.billingAddress,
                                                            Postcode: event.target.value
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>Post Code</label>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className='registration-address-container' ref={registrationRef}>
                            <h3>REGISTRATION ADDRESS</h3>
                            <Row>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="rego-address1" aria-describedby="rego-address1-help"
                                                    placeholder="Address Line 1"
                                                    value={pageState.regoAddress?.Address1}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            ...pageState,
                                                            regoAddress: {
                                                                ...pageState.regoAddress,
                                                                Address1: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                <label>Address Line 1</label>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="rego-suburb" aria-describedby="rego-suburb-help"
                                                    placeholder="Suburb"
                                                    value={pageState.regoAddress?.Suburb}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            ...pageState,
                                                            regoAddress: {
                                                                ...pageState.regoAddress,
                                                                Suburb: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                <label>Suburb</label>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="rego-suburb" aria-describedby="rego-suburb-help"
                                                    placeholder="Suburb"
                                                    value={pageState.regoAddress.State?.code}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        //setPageState({
                                                        //    ...pageState,
                                                        //    regoAddress: {
                                                        //        ...pageState.regoAddress,
                                                        //        State: e.target.value
                                                        //    }
                                                        //})
                                                    }}
                                                />
                                                <label>State</label>
                                                {/*<Dropdown*/}
                                                {/*    disabled={true}*/}
                                                {/*    value={pageState.regoAddress?.State}*/}
                                                {/*    options={states}*/}
                                                {/*    onChange={(e) => onRegoStateChange(e)}*/}
                                                {/*    className='global-location-dropdown'*/}
                                                {/*    optionLabel="name"*/}
                                                {/*    placeholder="State*"*/}
                                                {/*/>*/}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="rego-postcode" aria-describedby="rego-postcode-help"
                                                    placeholder="Post Code"
                                                    disabled={true}
                                                    value={pageState.regoAddress?.Postcode || ''}
                                                    style={{ borderBottom: invaidPageState.invalidRegoAddress.InvalidPostcode ? '3px solid red' : '2px solid #d2d2d2' }}
                                                    onKeyPress={(e: any) => isNumberKey(e)}
                                                    onInput={(event: any) => {
                                                        let primaryPostcode = event.target.value.slice(0, 4);
                                                        setPageState({
                                                            ...pageState,
                                                            regoAddress: {
                                                                ...pageState.regoAddress,
                                                                Postcode: event.target.value
                                                            }
                                                        });
                                                        //invalidPrimaryPostcode: isNil(event.target.value) || event.target.value == ""
                                                    }}
                                                />
                                                <label>Post Code</label>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='delivery-address-container' ref={deliveryRef}>                          
                            <h3>DELIVERY ADDRESS</h3>
                            <Row>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="delivery-address1" aria-describedby="delivery-address1-help"
                                                    placeholder="Address Line 1"
                                                    value={pageState.deliveryAddress?.Address1}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            ...pageState,
                                                            deliveryAddress: {
                                                                ...pageState.deliveryAddress,
                                                                Address1: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                <label>Address Line 1</label>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="delivery-suburb" aria-describedby="delivery-suburb-help"
                                                    placeholder="Suburb"
                                                    value={pageState.deliveryAddress?.Suburb}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            ...pageState,
                                                            deliveryAddress: {
                                                                ...pageState.deliveryAddress,
                                                                Suburb: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                <label>Suburb</label>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="rego-suburb" aria-describedby="rego-suburb-help"
                                                    placeholder="Suburb"
                                                    value={pageState.deliveryAddress.State?.code}
                                                    disabled={true}
                                                />
                                                <label>State</label>
                                                {/*<Dropdown*/}
                                                {/*    disabled={true}*/}
                                                {/*    value={pageState.deliveryAddress?.State}*/}
                                                {/*    options={states}*/}
                                                {/*    onChange={(e) => onDeliveryStateChange(e)}*/}
                                                {/*    className='global-location-dropdown'*/}
                                                {/*    optionLabel="name"*/}
                                                {/*    placeholder="State*"*/}
                                                {/*/>*/}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className='provide-info-item'>
                                    <div className='provide-info-inner'>
                                        <div className='input-item'>
                                            <span className="wrapper-box">
                                                <InputText id="rego-postcode" aria-describedby="rego-postcode-help"
                                                    placeholder="Post Code"
                                                    disabled={true}
                                                    value={pageState.deliveryAddress?.Postcode || ''}
                                                    style={{ borderBottom: invaidPageState.invalidDeliveryAddress.InvalidPostcode ? '3px solid red' : '2px solid #d2d2d2' }}
                                                    onKeyPress={(e: any) => isNumberKey(e)}
                                                    onInput={(event: any) => {
                                                        let primaryPostcode = event.target.value.slice(0, 4);
                                                        setPageState({
                                                            ...pageState,
                                                            deliveryAddress: {
                                                                ...pageState.deliveryAddress,
                                                                Postcode: event.target.value
                                                            }
                                                        });
                                                        //invalidPrimaryPostcode: isNil(event.target.value) || event.target.value == ""
                                                    }}
                                                />
                                                <label>Post Code</label>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='btn-panel'>
                        <div className='variant-btn-panel'>

                            {savingStatus == rdEnum.enumProcessingStatus.Processing && (
                                <React.Fragment>
                                    {/*<Button className='variant-btn'*/}
                                    {/*    style={{ marginRight: '1rem', border: '2px #060606 solid' }}>*/}
                                    {/*    <ProgressSpinner*/}
                                    {/*        style={{ width: '16px', height: '16px' }}*/}
                                    {/*        strokeWidth="5"*/}
                                    {/*        animationDuration=".8s"*/}
                                    {/*    />*/}
                                    {/*</Button>*/}
                                <Button className='variant-btn btnSubmitProvideDetails'>
                                        <ProgressSpinner
                                            style={{ width: '16px', height: '16px' }}
                                            strokeWidth="5"
                                            animationDuration=".8s"
                                        />
                                    </Button>
                                </React.Fragment>
                            )}
                            {savingStatus != rdEnum.enumProcessingStatus.Processing && (
                                <React.Fragment>
                                {/*<Button className='variant-btn'*/}
                                {/*    style={{ marginRight: '1rem', border: '2px #060606 solid', display: "none" }}*/}
                                {/*        onClick={(e: any) => {*/}
                                {/*            onSaveAndSubmit(false);*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <span>SAVE</span>*/}
                                {/*    </Button>*/}
                                    <Button className='variant-btn btnSubmitProvideDetails'
                                        onClick={(e: any) => {
                                            onSaveAndSubmit(true);
                                        }}
                                    >
                                        <span style={{ color: 'white' }}>SUBMIT</span>
                                    </Button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>


                    <div className={classNames({
                        "provide-details-message": true,
                        "text-error": !submitStatus,
                        "text-success": submitStatus
                    })}>
                        <div>{submitMessage}</div>
                    </div>
            </Dialog>
        </>
    )
}

export default ProvideDetails