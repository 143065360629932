import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { filter, includes, find, isNil, String, clone, isNull } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { useCheckMobileScreen } from 'helpers/useCheckMobileScreen';
import { IAppState } from 'model/IAppState';

import {
    getCMSAssets,
} from 'helpers/Uri';


const MaintenancePage = () => {
    return (
        <div className="maintenance-page">
            <div className="overlay-content">
                <img src={`${getCMSAssets()}/home/gm-logo-container-light.svg`} alt="Logo" />

                <h1>WE'LL BE RIGHT BACK.</h1>
                <p>We're making updates to the Cadillac Store. Please check back soon.</p>
                <button onClick={() => {
                    window.location.href = "https://www.cadillac.com";
                }}>LEARN ABOUT LYRIQ</button>
            </div>
        </div>
    )
}


export default MaintenancePage;